import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";

const Legacy = () => (
  <Layout>
    <Seo
      title="Legacy Products"
      description="Superbase Legacy Products - Superbase Classic Features"
    />
    <main>
      <div className="container">
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Superbase Classic is <strong>NOT</strong> 64-bit compatible. Use{" "}
          <a href="/products/ng-ide" className="alert-link">
            Superbase NG
          </a>{" "}
          for 64-bit compatiblity. Classic is a legacy product with NO SUPPORT!
        </div>
        <div className="px-4 pt-5 text-center">
          <h1 className="display-3">Superbase Classic</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              The World's First Relational Database. Designed to offer
              businesses a complete data management solution regardless of size.
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              <a
                href="https://order.shareit.com/product?productid=300373577&backlink=http%3A%2F%2Fwww.superbase.com%2Fproducts%2Fsuperbase-classic%2F"
                className="btn btn-primary btn-lg px-4 me-sm-3"
              >
                Buy it now
              </a>
            </div>
          </div>
        </div>
        <div className="container py-3">
          <h2 className="pb-2 border-bottom">Features</h2>
          <div className="row g-5 py-5">
            <div className="offset-1 col-md-4 d-flex align-items-start">
              <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                <svg className="bi" width="1.5em" height="1.5em">
                  <use href="#speedometer2"></use>
                </svg>
              </div>
              <div>
                <h2>Modern Features Built-In</h2>
                <p>
                  Comes with Email Support, OLE2 Automation for Microsoft
                  Office, COM1-COM9 Support, Form and Report Design Assistants
                  and Templates
                </p>
                <a href="#features" className="btn btn-outline-primary">
                  Learn more...
                </a>
              </div>
            </div>
            <div className="offset-2 col-md-4 d-flex align-items-start">
              <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                <svg className="bi" width="1.5em" height="1.5em">
                  <use href="#cpu-fill"></use>
                </svg>
              </div>
              <div>
                <h2>External Data Integration</h2>
                <p>
                  Access to ODBC and dBase clients, as well as Direct SQL
                  Connectivity to Oracle, SQLServer, DB2, SQLBase, and more...
                </p>
                <a href="#features" className="btn btn-outline-primary">
                  Learn More...
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-3" id="comparison">
          <h2 className="pb-2 border-bottom">Superbase Version comparison</h2>
          <div className="table-responsive">
            <table className="table text-center">
              <thead>
                <tr>
                  <th width="40%">Superbase Feature Upgrades</th>
                  <th width="10%">3.0/95</th>
                  <th width="10%">3.2</th>
                  <th width="10%">3.5</th>
                  <th width="10%">3.6i</th>
                  <th width="10%">2001</th>
                  <th width="10%">Classic</th>
                </tr>
                <tr>
                  <th>…since version 2.0 and earlier</th>
                  <th>December 1994</th>
                  <th>September 1997</th>
                  <th>August 1998</th>
                  <th>March 1999</th>
                  <th>August 2001</th>
                  <th>August 2003</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Upgraded Forms &amp; Reports</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Object oriented SBL</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Generate object code automatically</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Full SBL Reference online</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Object Properties Dialog Box</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Dialog Editor Enhancements</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Program Editor Enhancements</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Menu Item Application help</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Form Builder</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Report Builder</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>checktab Reports</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>List &amp; Combo Boxes on Forms</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Outer Joins in Reports</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Report Designer</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Electronic Mail</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Transparent SQL Access</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Improved Import/Export</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Image Support</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Multiple Document Interface MDI</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>ToolTips</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Icons &amp; Toolbars</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Pre-defined File Templates</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>SuperSteps</th>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>Command Line Editor</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Debugger</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>DiskFile Objects</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Rotatable TextBox Objects</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Document Window</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>File Level Encryption</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Huge Integer Object</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Image Filters</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>International Language Support</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Volatile Files</th>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>Border Style</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>CSV Converter</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>HTML Convertor</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Date &amp; Time Field Enhancements</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Euro Symbol support</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>File Engine Check &amp; Repair</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Long File names</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Oracle SQL Connector</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Split Window Mode</th>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>Peer-To-Peer Client/Server (PPCS)</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Graphic Report enhancement</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Remote File connectivity</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Multiple Language support</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Improved Form Scaling</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Win32 DLLs</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Multiple Arguments</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Enhanced Encryption</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>Enhanced Graphic Reports</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Start Window options</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>New Sort orders</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Program Editor Enhancements</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Project Manager</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Scientific Features</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>XML Import &amp; Export</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>Win32 Help Support</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th>Supports SBL Add-Ins</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <th>SuperView Data Mining Tool</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg className="bi" width="24" height="24">
                      <use href="#check"></use>
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="container py-3" id="features">
          <h3>Key Classic Features</h3>

          <h5>Unique Features Exclusive to Superbase:</h5>
          <ul>
            <li>
              The only database capable of displaying more than 250 file formats
            </li>
            <li>Unique rotatable textboxes</li>
            <li>
              The only database in the world that can handle huge integers up to
              122,880 digits in length
            </li>
            <li>RSA encryption supported with user-definable key lengths</li>
            <li>Huge integer mathematics</li>
            <li>Volatile (memory-based) files</li>
          </ul>

          <h5>Graphical Development Tools with Source Code Generation</h5>
          <ul>
            <li>
              Form Designer / Report Designer / Dialog Editor / Menu Editor /
              Icon Bar Editor / Macro Recorder
            </li>
          </ul>

          <h5>Object-Oriented Programming</h5>
          <ul>
            <li>
              Superbase Object Model / Object Browser / Superbase Basic Language
            </li>
          </ul>

          <h5>Modern Features Built-In</h5>
          <ul>
            <li>EMail Support – MAPI and VIM</li>
            <li>OLE2 Automation</li>
            <li>COM1-COM9 Supported for programmable communications</li>
            <li>Multidimensional Crosstab Queries</li>
            <li>File, Form, and Report Templates</li>
            <li>Form and Report Design Assistants</li>
          </ul>

          <h5>Open Access for Easy External Data Integration</h5>
          <ul>
            <li>ODBC and dBase</li>
            <li>Smart Queries</li>
            <li>Import/Export</li>
            <li>
              Direct SQL Connectivity to Oracle, SQLServer, DB2, SQLBase, XDB
            </li>
          </ul>

          <h5>Integrated Multimedia</h5>
          <ul>
            <li>
              Comes with internal image support plus external file/image API for
              external file/image filters
            </li>
            <li>Support for any MCI registered format (AVI, WAV, etc.)</li>
          </ul>
        </div>

        <div className="container py-3" id="requirements">
          <h3>System Requirements</h3>
          <ul>
            <li>
              Microsoft Windows 3.x, 95, 98, Me, NT, 2000, XP (32-bit), Vista
              (32-bit), Windows 7 (32-bit)
            </li>
            <li>PC using Intel 386 or higher processor</li>
            <li>4 MB RAM, 15 MB available disk space</li>
            <li>CD-ROM Drive</li>
          </ul>
        </div>
      </div>
    </main>
    <svg xmlns="http://www.w3.org/2000/svg" display="none">
      <symbol id="speedometer2" viewBox="0 0 16 16">
        <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"></path>
        <path
          fillRule="evenodd"
          d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
        ></path>
      </symbol>
      <symbol id="cpu-fill" viewBox="0 0 16 16">
        <path d="M6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path>
        <path d="M5.5.5a.5.5 0 0 0-1 0V2A2.5 2.5 0 0 0 2 4.5H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2A2.5 2.5 0 0 0 4.5 14v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14a2.5 2.5 0 0 0 2.5-2.5h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14A2.5 2.5 0 0 0 11.5 2V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5zm1 4.5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3A1.5 1.5 0 0 1 6.5 5z"></path>
      </symbol>
      <symbol id="toggles2" viewBox="0 0 16 16">
        <path d="M9.465 10H12a2 2 0 1 1 0 4H9.465c.34-.588.535-1.271.535-2 0-.729-.195-1.412-.535-2z"></path>
        <path d="M6 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm.535-10a3.975 3.975 0 0 1-.409-1H4a1 1 0 0 1 0-2h2.126c.091-.355.23-.69.41-1H4a2 2 0 1 0 0 4h2.535z"></path>
        <path d="M14 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"></path>
      </symbol>
      <symbol id="tools" viewBox="0 0 16 16">
        <path d="M1 0L0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z"></path>
      </symbol>
      <symbol id="check" viewBox="0 0 16 16">
        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
      </symbol>
    </svg>
  </Layout>
);

export default Legacy;
